import React, { Fragment } from 'react'
import { Global } from '@emotion/react'
import { globalStyles } from '../components/layout.styles'
import SEO from "../components/Seo"
import NavBar from '../components/NavBar3'
import Footer from '../components/Footer'
import Banner from '../images/new/banners/nossos-pilares.png'
import Cta from '../images/new/banners/nossos-pilares-2.png'
import Seguranca from '../images/new/banners/seguranca.svg'
import Afeto from '../images/new/banners/afeto.svg'
import Desenvolvimento from '../images/new/banners/desenvolvimento.svg'
import { 
  section,
  HeaderBanner,
  container,
  content,
  columns,
  column,
  mb32
} from '../components/styles/Base.styles'

const NossosPilares = props => (
  <Fragment>
    <Global styles={globalStyles} />
    <SEO title="Nossos Pilares | Um reino de aprendizado e conhecimento" />
    <NavBar />
    <div css={HeaderBanner}>
      <img src={Banner} alt='' style={{ width: '100%' }} />
    </div>
    <div css={[section, content]}>
      <div css={container}>
        <h1>Nossos Pilares</h1>
        <p>Somos uma escola que quebra barreiras e paradigmas. Encaramos o que acreditamos por meio de ações. Temos o compromisso de formar cidadãos do mundo, éticos, que saibam quem são, que compreendam seu potencial e abordem a vida com alegria, humildade e um profundo desejo de transformar o seu entorno. Nossa prática é focada em apoiar o desenvolvimento da mente e corpo por meio de abordagens curriculares dinâmicas e sintonizadas com as melhores práticas na área de educação.</p>
        <div css={columns}>
          <div css={column}>
            <div css={mb32} style={{ textAlign: 'center' }}>
              <img src={Seguranca} alt='' style={{ margin: 0, width: 'auto' }} />
              <h3>
                Segurança<br />
                <span>Física e Emocional.</span>
              </h3>
            </div>
          </div>
          <div css={column}>
            <div css={mb32} style={{ textAlign: 'center' }}>
              <img src={Afeto} alt='' style={{ margin: 0, width: 'auto' }} />
              <h3>
                Afeto<br />
                <span>Com si mesmo e com o próximo.</span>
              </h3>
            </div>
          </div>
          <div css={column}>
            <div css={mb32} style={{ textAlign: 'center' }}>
              <img src={Desenvolvimento} alt='' style={{ margin: 0, width: 'auto' }} />
              <h3>
                Desenvolvimento<br />
                <span>De como ser humano.</span>
              </h3>
            </div>
          </div>
        </div>
        <div style={{ marginTop: 32 }}>
          <img src={Cta} alt='' style={{ width: '100%' }} />
        </div>
      </div>
    </div>
    <Footer />
  </Fragment>
)

export default NossosPilares
